import customFetch from '@/libs/customFetch';
import permissions from '@/router/route-permissions';
import { defineStore } from 'pinia';
import { computed, reactive, ref } from 'vue';
import { useAlertaStore } from '@/store/modules/alertas';
import menuPerfis from '@/store/modules/perfis/menuPerfis';
import router from '@/router';
import { useRecuperaEmail } from "@/store/modules/recaptcha";

export const useAdminStore = defineStore('admin', () => {
  const usuario = ref({});
  const usuarioLogado = computed(() => usuario.value);
  const usuarioTemPermissao = computed(
    () => (rota) => usuario.value && permissions[rota]?.includes(usuario.value.role)
  );

  async function login(login, password) {
    const { sucesso } = useAlertaStore();
    const res = await customFetch('/user/login', {
      method: 'POST',
      mode: 'cors',
      body: { login, password },
    });

    gravaUsuario(await res.json());
    sucesso('Usuário logado com sucesso');
  }

  function gravaUsuario(logado) {
    usuario.value = logado;
    localStorage.setItem('token', logado.token);
    localStorage.setItem('usuario', JSON.stringify(logado));
  }

  function logout() {
    usuario.value = {};
    localStorage.removeItem('token');
    localStorage.removeItem('usuario');
    const recaptcha = useRecuperaEmail();
    recaptcha.recaptcha.hashcaptcha = null;
  }

  function checkIsLogado(callbackSucesso = () => {}, callbackErro = () => {}) {
    const logado = localStorage.getItem('usuario');

    if (logado) {
      gravaUsuario(JSON.parse(logado));
      if (logado.role !== 'consumidor') {
        return callbackSucesso();
      }

      return callbackErro();
    }

    return callbackErro();
  }

  function userLogado() {
    if (usuario.value.role) {
      return usuario.value.role.charAt(0).toUpperCase() + usuario.value.role.slice(1);
    }
  }

  async function getConfig() {
    let result = await customFetch('/user/config', {
      method: 'GET',
      mode: 'cors',
      headers: {
        'Content-type': 'application/json',
      },
    });
    let jsonResponse = await result.json();
    return jsonResponse;
  }

  function checkToken() {
    let tokenLocal = localStorage.getItem('token') !== null ? localStorage.getItem('token') : undefined;
    if(!tokenLocal){
      router.push('/')
    } else {
      this.allowDataLoad = true;
    }
  }

  return {
    usuario,
    usuarioTemPermissao,
    usuarioLogado,
    login,
    logout,
    checkIsLogado,
    userLogado,
    getConfig,
    checkToken
  };
});
